import axios from '@/plugins/axios'
import customErrors from '@/store/error'
import {DICTIONARY_TYPES} from '@/helpers/variables/dictionaryTypes'
import convertObjToArray from '@/helpers/convertObjToArray'
import convertWithRootToArray from '@/helpers/convertWithRootToArray'
import {translateAndSortTypeObj} from '@/helpers/translator'
import {v4 as uuidv4} from 'uuid'
import i18n from '@/i18n/i18n'

const DEFAULT_PARAMS = {
  value: undefined,
  page: 0,
  size: 300,
  lang: i18n.locale.toUpperCase(),
}

const MAP_WITH_GROUP_ITEMS = (data, groupFiled, valueFiled, lastEl, searchValue) => {
  let rootObj = {}

  return data.length
    ? data
        .map((a) => ({text: a[groupFiled], name: a[valueFiled], backValue: a?.value}))
        .reduce((agg, curr) => {
          if (!curr.text) {
            rootObj = {
              id: uuidv4(),
              rootId: null,
              isRoot: true,
              isException: true,
              text: curr.name,
              value: curr.name,
              isOpen: !!searchValue,
            }
            agg.push(rootObj)
          }

          if (curr.text) {
            const foundObj = agg.find((x) => x.text === curr.text)
            if (!foundObj) {
              rootObj = {
                id: uuidv4(),
                rootId: null,
                isRoot: true,
                text: curr.text,
                isValue: false,
                isOpen: !!searchValue,
              }

              if (rootObj?.text !== lastEl?.text) {
                agg.push(rootObj)
              }
            }

            const rootId = rootObj?.text === lastEl?.text ? lastEl.rootId : rootObj.id

            agg.push({
              id: uuidv4(),
              rootId,
              isRoot: false,
              text: curr.text,
              value: curr.name,
              backValue: curr.backValue,
            })
          }

          return agg
        }, [])
    : []
}

const MAP_SAFETY_ITEMS = (data) => {
  const items = {}
  for (const key in data) {
    items[key] = data[key].map((a) => {
      return {
        value: a,
        text: `safetyCategoryItems.${[a]}` ?? a,
      }
    })
  }
  // TODO: for 5 items
  // for (const key in data) {
  //   let count = 5
  //   let num = 1
  //   items[key] = data[key].map((a, i) => {
  //     if (i + 1 === count) {
  //       count = i + 1 + count
  //       num = num + 1
  //     }
  //     return {
  //       value: a,
  //       text: `safetyCategoryItems.${[a]}` ?? a,
  //     }
  //   })
  // }
  return items
}

const state = () => ({
  geoItems: [],
  deviceTypesWhiteItems: [],
  iabWhiteItems: [],
  carrierWhiteItems: [],
  makeWhiteItems: [],
  Android: [],
  iOS: [],
  Other: [],
  connectionTypesWhiteItems: [],
  osBlackItems: [],
  iabBlackItems: [],
  safetyItems: {},
  loading: false,
  params: {},
  dataPagination: {},
  trackerItems: [],
})

const getters = {}

const mutations = {
  SET_DICTIONARY(state, {name, view, groupFiled, valueFiled, data, isStart, activeTab, type}) {
    const {value: searchValue} = state.params[type]
    switch (view) {
      case 'geo':
        state[name] = convertObjToArray(data, true)
        break
      case 'iab':
        if (isStart) {
          state[name] = convertWithRootToArray(data)
        } else {
          state[name] = [...state[name], ...convertWithRootToArray(data)]
        }
        break
      case 'safety':
        if (isStart) {
          state[name] = MAP_SAFETY_ITEMS(data)
        } else {
          state[name] = [...state[name], ...MAP_SAFETY_ITEMS(data)]
        }
        break
      case 'group':
        if (isStart) {
          state[name] = MAP_WITH_GROUP_ITEMS(data, groupFiled, valueFiled, undefined, searchValue)
        } else {
          const lastEl = state[name][state[name].length - 1]
          state[name] = [
            ...state[name],
            ...MAP_WITH_GROUP_ITEMS(data, groupFiled, valueFiled, lastEl, searchValue),
          ]
        }
        break
      case 'model':
        if (isStart) {
          state[activeTab] = data
        } else {
          state[activeTab] = [...state[name], ...data]
        }
        break
      default:
        if (isStart) {
          state[name] = data
        } else {
          state[name] = [...state[name], ...data]
        }
    }
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_PAGE(state, {type, page, isStart}) {
    if (isStart) {
      state.params = {
        ...state.params,
        [type]: {
          ...DEFAULT_PARAMS,
          page,
        },
      }
    } else {
      state.params[type] = {
        ...DEFAULT_PARAMS,
        page: state.params[type].page + 1,
      }
    }
  },
  SET_PARAMS_VALUE(state, {type, params}) {
    state.params[type] = {
      ...state.params[type],
      ...params,
    }
  },
  SET_PAGINATION(state, {type, data}) {
    state.dataPagination = {
      ...state.dataPagination,
      [type]: {
        number: data.number,
        last: data.last,
        totalPages: data.totalPages,
        totalElements: data.totalElements,
      },
    }
  },
  RESET_DEFAULT_PARAMS(state, type) {
    state.params[type] = DEFAULT_PARAMS
  },
  SET_CREATIVE_TRACKERS(state, items) {
    state.trackerItems = translateAndSortTypeObj(items, 'trackerItems')
  },
}

const actions = {
  async GET_DICTIONARY({commit, dispatch, state}, {type, page, params, isStart, activeTab}) {
    const {name, view, groupFiled, valueFiled, withParams, url: apiUrl} = DICTIONARY_TYPES[type]
    const dictionaryType = type === 'model' ? activeTab : type
    commit('SET_LOADING', true)
    commit('SET_PAGE', {type: dictionaryType, page, isStart})
    commit('SET_PARAMS_VALUE', {type: dictionaryType, params})
    try {
      let params = {}
      if (withParams) params = state.params[dictionaryType]
      const url = `dictionary/target/${apiUrl}`
      const res = await axios.get(url, {params})
      if (res.status === 200) {
        commit('SET_DICTIONARY', {
          name,
          view,
          groupFiled,
          valueFiled,
          data: withParams ? res.data.content : res.data,
          isStart,
          activeTab,
          type: dictionaryType,
        })
        if (withParams) {
          commit('SET_PAGINATION', {
            type: dictionaryType,
            data: {
              number: res.data.number,
              last: res.data.last,
              totalPages: res.data.totalPages,
              totalElements: res.data.totalElements,
            },
          })
        }
      }
    } catch (err) {
      await customErrors(err, dispatch)
      commit('RESET_DEFAULT_PARAMS', dictionaryType)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async GET_COUNTRY({commit, dispatch, state}, {value, page, isStart, url, countryCode}) {
    commit('SET_LOADING', true)
    const params = countryCode ? {countryCode, regionCode: value} : {value}
    commit('SET_PAGE', {type: value, page, isStart})
    commit('SET_PARAMS_VALUE', {type: value, params})
    try {
      const params = state.params[value]
      const res = await axios.get(`dictionary/target/${url}`, {params})
      if (res.status === 200) {
        commit('SET_DICTIONARY', {
          name: value,
          data: res.data.content,
          isStart,
        })
        commit('SET_PAGINATION', {
          type: value,
          data: {
            number: res.data.number,
            last: res.data.last,
            totalPages: res.data.totalPages,
            totalElements: res.data.totalElements,
          },
        })
      }
    } catch (err) {
      await customErrors(err, dispatch)
      commit('RESET_DEFAULT_PARAMS', value)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async CREATIVE_TRACKERS({commit, dispatch}, type) {
    try {
      const toLowerType = type.toLowerCase()
      const res = await axios.get(`dictionary/creative/${toLowerType}/trackers/read-all`)
      if (res.data && res.status === 200) {
        commit('SET_CREATIVE_TRACKERS', res.data)
      }
    } catch (err) {
      await customErrors(err, dispatch)
      return err
    }
  },
  async CREATIVE_DATA_TYPES({dispatch}) {
    try {
      const res = await axios.get('dictionary/creative/native/data-type/read-all')
      if (res.data && res.status === 200) {
        const {data} = res
        return translateAndSortTypeObj(data, 'dataTypesItems')
      }
    } catch (err) {
      await customErrors(err, dispatch)
      return err
    }
  },
  async GET_DEFAULT_DICTIONARY_ITEMS({dispatch}, {url, fromData = false}) {
    try {
      const params = {page: 0, size: 1000}
      const res = await axios.get(url, {params})
      if (res.data && res.status === 200) {
        return fromData ? res.data : res.data.content
      }
    } catch (err) {
      await customErrors(err, dispatch)
      return err
    }
  },
  // async GET_CAMPAIGN_DICTIONARY_ITEMS({dispatch}, url) {
  //   try {
  //     const urls = [
  //       {
  //         url: 'dictionary/campaign/start-delay/read-all',
  //         value: 'startDelayItems',
  //       },
  //       {
  //         url: 'dictionary/campaign/audio/feed/read-all',
  //         value: 'feedItems',
  //       },
  //     ]
  //     let items = {}
  //     for (const item of urls) {
  //       const res = await axios.get(item.url)
  //       if (res.data && res.status === 200) {
  //         items = {
  //           ...items,
  //           [item.value]: convertObjToArray(res.data),
  //         }
  //       }
  //     }
  //     return items
  //   } catch (err) {
  //     await customErrors(err, dispatch)
  //     return err
  //   }
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
