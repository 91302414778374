import Vue from 'vue'
import VueRouter from 'vue-router'
import {getItem} from '@/helpers/persistantStorage'
import store from '@/store'
import {roles} from '@/helpers/variables/roles'

import admin from './modules/admin'
import manager from './modules/manager'
import advertiser from './modules/advertiser'

const adminRole = roles[0].value
const managerRole = roles[1].value
const advertiserRole = roles[2].value
const advertiserProRole = roles[3].value
const advertiserManagerRole = roles[4].value

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/login',
    name: 'Login',
    meta: {layout: 'empty'},
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {layout: 'main', auth: true},
    component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Profile'),
  },
  {
    path: '/public/advertiser/:inn',
    name: 'Public',
    meta: {layout: 'empty'},
    props: true,
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/Index.vue'),
  },
  {
    path: '/rp',
    name: 'ResetPassword',
    meta: {layout: 'empty'},
    component: () => import(/* webpackChunkName: "public" */ '@/views/auth/ResetPassword.vue'),
  },
  {
    path: '*',
    name: 'Error',
    redirect: () => {
      const token = getItem('nt_token')
      const role = getItem('nt_role')
      if (token) {
        if (role === adminRole) return {name: 'AdminDashboard'}
        if (role === managerRole) return {name: 'ManagerDashboard'}
        if (role === advertiserRole || role === advertiserProRole || role === advertiserManagerRole)
          return {name: 'AdvertiserDashboard'}
      }
      return {name: 'Login'}
    },
  },
]

const routes = baseRoutes.concat(admin, manager, advertiser)

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((m) => m.meta.auth) && !store.state.auth?.userInfo?.email) {
    await store.dispatch('auth/CHECK_USER')
    const role = store.state.auth?.userInfo?.roles[0]
    const permissions = store.state.auth?.permissions
    const pagePermission = to?.meta?.permission
    const isEntrance = permissions?.some((a) => a.startsWith(pagePermission))

    if (to.name === 'Profile') {
      return next()
    }

    if (to.name === 'Public') {
      return next()
    }

    if (role && permissions?.length === 0) {
      return next({name: 'Profile'})
    }

    if (role) {
      const serverPermission = permissions[0]?.split('_')
      const page = serverPermission[0]?.charAt(0).toUpperCase() + serverPermission[0]?.slice(1).toLowerCase()

      if (role === adminRole) {
        if (to.meta.role?.includes(role) && isEntrance) {
          next()
        } else {
          next({name: `Admin${page}`})
        }
      }
      if (role === managerRole) {
        if (to.meta.role?.includes(role) && isEntrance) {
          next()
        } else {
          next({name: `Manager${page}`})
        }
      }
      if (role === advertiserRole || role === advertiserProRole || role === advertiserManagerRole) {
        if (to.meta.role?.includes(role) && isEntrance) {
          next()
        } else {
          next({name: `Advertiser${page}`})
        }
      }
    } else {
      next({name: 'Login'})
    }
  }

  if (to.matched.some((m) => !!m.meta.role)) {
    if (to.matched.some((m) => !!m.meta.auth)) {
      next()
    } else {
      next({name: 'Login'})
    }
  } else {
    next()
  }
})

export default router
